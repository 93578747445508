<template>
  <div class="menuStyle">
    <div class="menuLogo"><img src="../static/logo1.png" alt=""></div>
    <div class="menuNav">
      <ul>
        <li :class="item.cataLoguePCR == $route.path?'drop-down_active drop-down_active_lgs':'drop-down'" v-for="item in NavData" :key="item.id">
          <template v-if="item.name == '展厅VR'">
            <a><span class="firstTitle"  @click="goVR(item)">{{item.name}}</span></a>
          </template>
          <template v-else>
            <a><span class="firstTitle"  @click="menuClick(item)">{{item.name}}</span></a>
            <div class="topIcon" v-if=" item.chilren !== undefined && item.chilren.length >0 "><i class="el-icon-caret-top"></i></div>
            <div class="drop-down-content">
              <div v-for="item1 in item.chilren" :key="item1.id">
                <div class="chilrenTitle" @click="menuClick(item1)">{{item1.name}}</div>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
    <div class="serchIcon">
      <div @click="goOtherDoors">商用门></div>
      <div @click="serch"><i class="el-icon-search" style="color:#ffffff;font-weight: 600;font-size: 26px;" ></i></div>
    </div>
    <div class="serchInput" v-show="serchInputShowOrHiden">
      <div style="width:60%;margin: 15px 20%;">
        <el-input @keyup.enter.native="goSerchPage(serchData)" placeholder="请输入内容" v-model="serchData">
          <template slot="append"><div @click="goSerchPage(serchData)">搜索</div></template>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
    export default{
        name: "Menu",
        data() {
          return {
            NavData:[],
            serchData:'',
            serchInputShowOrHiden: false,
        }
        },
        methods: {
        serch(){
          this.serchInputShowOrHiden = !this.serchInputShowOrHiden;
        },
        goSerchPage(data){
          this.$router.push({
            name:'SerchPage',
            query:{
              data
            }
          });
          this.serchInputShowOrHiden = false
        },
        goOtherDoors(){
          var url = 'https://www.svndoor.com'
          window.open(url,"_self");
        },
        
        goVR(item){
          window.open(item.cataLoguePCR,"_blank");
        },
        getMenuData(){
          this.$http.get('/system/situation/getTreeR').then(res => {
            let ZtVR = {
              cataLoguePCR: "https://0e5m1efueov.720yun.com/t/63vktwqlsqm",
              name:'展厅VR'
            }
            // let NavProfile = [{id:8,level:2,name:'企业介绍',path:'/CompanyProfile',pointId:'item1'},
            //           {id:9,level:2,name:'企业优势',path:'/CompanyProfile',pointId:'item2'},
            //           {id:10,level:2,name:'资质荣誉',path:'/CompanyProfile',pointId:'item3'},]     
            this.NavData = res
            // console.log('this.NavData00000000',this.NavData);
            this.NavData.push(ZtVR)
            // this.NavData.forEach(dataRes=>{
            //   if(dataRes.name == '公司介绍'){
            //     dataRes.chilren.forEach(data=>{
            //       data.pointId = ''
            //       NavProfile.forEach(dataAdd =>{
            //         if(data.name == dataAdd.name){
            //           data.pointId += dataAdd.pointId
            //         }
            //       })
            //     })
            //   }
            // })
            // console.log('this.NavData',this.NavData);
          })
        },
        menuClick(item) {
          if((item.cataLoguePCR == '/ProductCenter' || item.cataLoguePCR == '/EngineeringCase') && !item.chilren){
            sessionStorage.setItem('TypeId',item.id)
            this.$router.push({ 
              path: item.cataLoguePCR,
            });
            // this.$emit("menuChange",item.id);
            location.reload()
          }else{
            sessionStorage.removeItem('TypeId')
            this.$router.push({ 
              path: item.cataLoguePCR
            });
          }
        }
      },
      mounted(){
        this.getMenuData();
      },
      }
</script>

<style lang="scss" scoped>
.menuStyle{
    width: 100%;
    position: fixed;
    z-index: 99999;
    height: 80px;
    display: flex;
    top: 0;
    background-color:rgba(0, 0, 0, 0.8);
  }
.menuLogo{
  width: 30%;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  align-items: center;
  img{
    width: 120px;
    height: 43px;
  }
} 
  .serchIcon{
    font-size: 18px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-right: 80px;
  }
  .serchIcon div:first-child{
    padding: 4px 6px;
    margin-right: 6px;
    white-space:nowrap;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
  }
  .menuNav{
  width: 100%;
  display: flex;
}
.menuNav ul{
  list-style-type: none;
  font-size: 16px;
  font-family: Source Han Sans CN;
  // font-weight: bold;
  color: #FFFFFF;
  display: flex;
  margin: unset !important;
  padding: unset !important;
  height: 80px;
  align-items: center;
}
.menuNav ul li{
  white-space: nowrap;
  width: 120px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.drop-down_active .firstTitle{
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 4px solid #FFFFFF;
}
.drop-down:hover .firstTitle{
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 4px solid #FFFFFF;
}
.drop-down:hover,.drop-down_active:hover{
  .drop-down-content,.topIcon{
    display: block;
  }
}
.drop-down-content,.topIcon{
  display: none;
}
.drop-down-content{
  position: absolute;
  top: 80px;
  width: 120px;
  text-align: center;
  font-size: 14px;
  background: rgba(248,248,248,0.4);
  color: #333333;
}
.chilrenTitle{
  margin: 10px 0;
}
.chilrenTitle:hover{
  color: #FFFFFF;
}
.topIcon{
  position: absolute;
  color: rgba(248,248,248,0.4);
  top: 66px;
}
.serchInput{
  background-color:rgba(32, 32, 32, 0.5);
  height: 70px;
  width: 100%;
  position: absolute;
  margin-top: 80px;
  z-index: 9999;
  border-top: 1px solid white;
}
</style>