<template>
  <div class="content">
      <!-- <Menu></Menu> -->
      <TopBanner :srcTop = "srcTop"  :object="false"></TopBanner>
      <div style="margin-top: -4px;">
          <div class="contactWay">
              <div class="contactWay_left">
                  <img :src="phoneIcon" alt="">
                  <div>
                      <div>咨询热线：</div>
                      <div class="secWord">400-023-5968</div>
                  </div>
              </div>
              <div class="contactWay_right">
                  <img :src="positionIcon" alt="">
                  <div>
                      <div>公司地址：</div>
                      <div class="secWord">重庆市南岸区白鹤路56号</div>
                  </div>
              </div>
          </div>
          <div class="contactMap">
              <img :src="mapImg" alt="">
          </div>
          <div class="contactQRcode">
              <div><img :src="QRcodeIcon" alt="" style="width: 280px;height:280px;"></div>
              <div>扫码关注萨瓦尼公众号</div>
          </div>
      </div>
      <Footer>
        <template #footerBanner>
          <img :src=" $SrcUrl  + srcFooter" alt="">
        </template>
      </Footer>
  </div>
</template>

<script>
import Menu from "@/components/Menu"
import Footer from "@/components/Footer"
import TopBanner from "@/components/TopBanner"
import { pictureData } from "@/utils/advertiseImgData"

export default {
  name: "ContactUs",
  components: {
    Menu,Footer,TopBanner
  },
  data () {
    return { 
      phoneIcon:[require('@/static/phoneIconBlack.png')],
      positionIcon:[require('@/static/positionIcon.png')],
      mapImg:[require('@/static/contackMap.png')],
      QRcodeIcon:[require('@/static/QRcode1.jpg')],
      srcTop:[],
      srcFooter:[require('@/static/fooderImg1.png')],
    }
  },
  methods: {
    
    getAdvertise(){
      this.$http.get('/system/advertisement/getAdvR').then(res => {
        let { topImg, footerImg } = pictureData({picExplain:'联系我们', data:res})
        if(topImg .length > 0){
          this.srcTop = topImg[0].data;
        }
        if(footerImg .length > 0){
          this.srcFooter = footerImg[0].data
        }
      })
    },
  },
  mounted(){
    this.getAdvertise();
  },
  computed: {
    windowHeight:function() {
      return (window.innerHeight) + 'px';
    }
  }
}
</script>

<style scoped>
.content{
  width: 100%;
}
.contactWay{
  display: flex;
  width: 100%;
  justify-content: center;
  height: 120px;
  background: #D8D8D8;
}
.contactWay .contactWay_left,.contactWay_right{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px
}
.contactWay div{
  margin: 6px 0;
}
.contactWay .contactWay_left img,.contactWay_right img{
  margin: 0 16px;
}
.contactWay .contactWay_left .secWord,.contactWay_right .secWord{
  font-size: 30px;
}
.contactMap{
  /* margin: 80px 200px; */
  width: 62%;
  margin: 80px 19%;
  display: flex;
  justify-content: center;
}
.contactMap img{
  width: 100%;
}
.contactQRcode{
  margin: 30px auto;
}
.contactQRcode div{
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 20px 0;
}
</style>