<template>
  <div class="content">
      <!-- <Menu></Menu> -->
      <TopBanner :srcTop = "srcTop"  :object="false"></TopBanner>
      <div>
        <Module ModuleTitle1="新闻中心" ModuleTitle2="" ModuleTitleEng="Press center">
          <template #content>
            <div class="newsAllContent">
              <div v-for="(item,index) in newsData" :key="item.id">
                <div @click="activeStyle(index)" :class="{ active: index == types }" class="newsContent">
                  <div  @click="goDetailsPage(item)" class="newsContents_left" :style="{'height':newsImgFitWidth + 'px','margin':'auto 0'}">
                    <img :src="$SrcUrl  + item.newsPicture" alt="" class="pic1">
                    <div class="hoverSeeIcon">
                      <img src="../static/seeIcon.png" alt="">
                    </div>
                  </div>
                  <div class="newsContents_right">
                    <div>
                      <div class="newsTitle jianju">{{item.name}}</div>
                      <div class="newsText jianju">{{item.textContent}}</div>
                      <div class="jianju"><button type="" @click="goDetailsPage(item)">了解详情></button></div>
                      <div class="newsTime jianju">{{item.beginTime}}</div>
                    </div>
                  </div>
                </div>
                <div style="height:2px;width:100%;background:#E3E3E3;"></div>
              </div>
            </div>
            <div class="newsPagination">
              <el-pagination  
                  prev-text="上一页" next-text="下一页"
                  background
                  layout="prev, pager, next"
                  :page-size="currentSize"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :total="total">
              </el-pagination>
            </div>
          </template>
        </Module>
      </div>
      <Footer>
        <template #footerBanner>
          <img :src=" $SrcUrl  + srcFooter" alt="">
        </template>
      </Footer>
  </div>
</template>

<script>
import Menu from "@/components/Menu"
import Module from "@/components/Module"
import Footer from "@/components/Footer"
import TopBanner from "@/components/TopBanner"

import { pictureData } from "@/utils/advertiseImgData"

export default {
  name: "CompanyProfile",
  components: {
    Menu,Module,Footer,TopBanner
  },
  data () {
    return {
      newsImgFitWidth:'',
      windowHeight:'',
      currentPage:1,
      currentSize:3,
      total:0,
      srcTop:[],
      srcFooter:[require('@/static/fooderImg1.png')],
      newsData:[],
      lastNewsData:[],
      types:0,
    }
  },
  methods: {
    getAdvertise(){
      this.$http.get('/system/advertisement/getAdvR').then(res => {
        let { topImg, footerImg } = pictureData({picExplain:'新闻中心', data:res})
        console.log('topImg',topImg);
        console.log('footerImg',footerImg);
        if(topImg .length > 0){
          this.srcTop = topImg[0].data;
        }
        if(footerImg .length > 0){
          this.srcFooter = footerImg[0].data
        }
      })
    },
    goDetailsPage(item){
      this.$router.push({
        name:'DetailsPage',
        query:{
          lastPage:'newsCenter',id: item.id,
        }
      })
    },
    handleCurrentChange(val){
      this.currentPage = val
      this.getNewsData();
    },
    getNewsData(){
      this.$http.get('/system/news/getNewsR',{'pageNum':this.currentPage, 'pageSize': this.currentSize}).then(res => {
        if(res.code == 200){
          this.newsData = res.rows;
          this.total = res.total;
          console.log('this.newsData',this.newsData);
          this.newsData.forEach(item =>{
            item.textContent = '';
            const regex = new RegExp('(<\\/[a-z]+[0-9]*>|<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="(([a-z]+|-|[0-9]*)*|:|(V)+|\\.)*")*>)|(<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="([a-z]+|-|:|(\\s\\S)|(\\/)*|\\.|[0-9]*|_|[A-Z]*|\\(|,|\\)|;|[\u4e00-\u9fa5])*")*>)', 'g')
            let descript = regex[Symbol.replace](item.content, '')
            item.textContent += descript
          })
          if(this.newsData){
            setTimeout(()=>{
                const elementResizeDetectorMaker = require("element-resize-detector");
                let erd = elementResizeDetectorMaker();
                erd.listenTo(document.querySelector(".newsContents_left"), (ele) => {
                this.newsImgFitWidth = ele.offsetWidth*0.7
              });
            },100)
          }
          // console.log('this.newsData',this.newsData);
          
          // let div = document.createElement("div") ;
          // for (let i = 0; i < this.newsData.length; i++) {
          // this.newsData[i].textContent = '';
          //   div.innerHTML = this.newsData[i].content;
          //   let pDoms = div.querySelectorAll("p");
          //   pDoms.forEach(item=>{
          //     this.newsData[i].textContent += item.innerText;
          //   })
          // }
          // div.remove();
        }
      })
    },
    activeStyle(index){
      this.types = index
    },
  },
  mounted(){
    this.windowHeight = window.innerHeight;
    this.getNewsData();
    this.getAdvertise();
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
}
.newsAllContent{
  width: 56%;
  margin: 10px auto;
}
.active{
  background: #F5F5F5;
}

.newsContent{
  display: flex;
  margin: 30px 0;
  border-radius: 12px 0px 0px 12px;
}
.newsContent:hover{
  background: #F5F5F5;
}
.newsContent .newsContents_left{
  position: relative;
  width: 38%;
  border-radius: 12px;
  overflow: hidden;
} 
.newsContent .newsContents_left .pic1{
  width: 100%;
  height: 100%;
  z-index: -1;
}
.hoverSeeIcon{
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  border-radius: 12px;
  visibility: hidden;
}

.newsContent .newsContents_left:hover{
  .hoverSeeIcon{
    visibility: visible;
  }
}
.newsContents_right{
  display: flex;
  color: #000000;
  align-items: center;
  flex: 1;
  font-family: Source Han Sans CN;
}
.newsContents_right div{
  // margin: 2% 3% 2% 8%;
  .leaveMsg form input, textarea, button{
    width: 108px;
    height: 36px;
    background: rgba($color: white, $alpha: 1.0);
    border: 1px solid #A7A7A7;
    border-radius: 6px;
  }
}
.newsTitle{
  font-size: 22px;
  font-weight: 500;
}
.jianju{
  margin: 2% 3% 2% 8%;
}
.newsText{
  font-size: 18px;
  // font-size:calc(100vw * 18 / 1920);
  margin-right: 12%!important;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden!important;;				//溢出内容隐藏
  text-overflow: ellipsis;		//文本溢出部分用省略号表示
  display: -webkit-box;			//特别显示模式
  -webkit-line-clamp: 2;			//行数
  line-clamp: 2;					
  -webkit-box-orient: vertical;	//盒子中内容竖直排列
}
.newsTime{
  display: flex;
  justify-content: right;
}

// 分页
.newsPagination{
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.newsPagination ::v-deep .el-pagination.is-background .btn-next,
.newsPagination ::v-deep  .el-pagination.is-background .btn-prev,
.newsPagination ::v-deep .el-pagination.is-background .el-pager li {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
}

.newsPagination ::v-deep .el-pagination.is-background .el-pager li:hover{
  color: #000000;
}
.newsPagination ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #000000;
  color: #fff;
}
.newsPagination ::v-deep .el-pagination .btn-prev,.newsPagination ::v-deep .el-pagination .btn-next {
  width: 64px;
}
</style>