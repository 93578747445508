import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './utils/http';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "quill/dist/quill.core.css";

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'


// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
import Video from 'video.js'
import 'video.js/dist/video-js.css' 
Vue.prototype.$video = Video



// 监听session Storage的变化
Vue.prototype.setSessionItem = function (key,) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k,) {
      sessionStorage.setItem(k, val);

      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};



Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$SrcUrl ='https://api.svndoor.com', 

Vue.use(ElementUI)

new Vue({
  router,
  store,
  http,
  render: h => h(App)
}).$mount('#app')
