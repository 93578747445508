<template>
    <div class="aboutUs" style="display:flex;justify-content:center;">
        <div class="aboutUsContent">
            <div class="aboutUsContent_left">
                <template v-if="$route.path == '/Main'">
                    <img class="leftImg leftImg1" :src=" $SrcUrl  +  companyIntro.picture_0" alt="">
                </template>
                <template v-else>
                    <img class="leftImg leftImg1" :src=" $SrcUrl  +  companyIntro.picture_1" alt="">
                </template>
            </div>
            <div class="aboutUsContent_right">
                <div style="width: 100%;" v-if="showOrHide">
                    <div class="introText">
                        <p v-html="companyIntro.textContent"></p>
                    </div>
                    <span @click="goIntro">查看更多></span>
                </div>
                <div style="width: 100%;" v-else>
                    <div v-html="companyIntro.pageContent"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default{
    props:{
        showOrHide: {
            type: Boolean,
            default: true
        },
    },
    name: "AboutUs",
    data() {
      return {
        companyIntro:{},
      }
    },
    mounted(){
        
        if(this.$route.path == '/CompanyProfile' || this.$route.path == '/Introduction'){
            // setTimeout(()=>{
            //     console.log('document.querySelector(.aboutUsContent_right',document.querySelector('.aboutUsContent_right').clientHeight + 'px');
            //     document.querySelector('.aboutUsContent_left').style.height = document.querySelector('.aboutUsContent_right').clientHeight + 'px'
            //     document.querySelector('.leftImg').style.width = document.querySelector('.aboutUsContent_left').clientHeight + 66 + 'px'
                
            // },100)
            document.querySelector('.leftImg1').classList.remove("leftImg");
        }else{
            // document.querySelector('.leftImg').classList.remove("leftImg1");
        }

        this.$http.get('/system/situation/getId/' + 27).then(res => {
            this.companyIntro = res
            this.companyIntro.picture_0 = this.companyIntro.picture.split(',')[0]
            this.companyIntro.picture_1 = this.companyIntro.picture.split(',')[1]
            this.companyIntro.textContent = '';

            let div = document.createElement("div") ;
            div.innerHTML = this.companyIntro.pageContent;
            let pDoms = div.querySelectorAll("p");
            pDoms.forEach(item=>{
                this.companyIntro.textContent += item.innerText + '</br>';
            })

            // console.log('this.companyIntro',this.companyIntro);
            // for (let i = 0; i < this.newsData.length; i++) {
            // this.newsData[i].textContent = '';
            //     div.innerHTML = this.newsData[i].content;
            //     let pDoms = div.querySelectorAll("p");
            //     pDoms.forEach(item=>{
            //     this.newsData[i].textContent += item.innerText;
            //     })
            // }
            // div.remove();

            // const regex = new RegExp('(<\\/[a-z]+[0-9]*>|<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="(([a-z]+|-|[0-9]*)*|:|(V)+|\\.)*")*>)|(<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="([a-z]+|-|:|(\\s\\S)|(\\/)*|\\.|[0-9]*|_|[A-Z]*|\\(|,|\\)|;|[\u4e00-\u9fa5])*")*>)', 'g')
            // let descript = regex[Symbol.replace](this.companyIntro.pageContent, '')
            // console.log('descript',descript);
            // this.companyIntro.textContent = descript;
        })
    },
    methods:{
        goIntro(){
            this.$router.push({
                name:'Introduction'
            })
        }
    }
  }
</script>

<style scoped>
.aboutUsContent{
    width: 62%;
    margin: 20px;
    display: flex;
    align-items: center;
}
.aboutUsContent .aboutUsContent_left,.aboutUsContent .aboutUsContent_right{
    width: 50%;
    margin: 0 30px;
    text-align: justify;
    justify-content: center;
    display: flex;
}
.aboutUsContent .aboutUsContent_left .leftImg{
    width: 100%;
    height: 100%;
}
.aboutUsContent .aboutUsContent_left .leftImg1{
    width: 80%;
    height: 80%;
}
.introText{
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:9; 
}
.aboutUsContent div:last-child{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
}
</style>