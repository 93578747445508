<template>
    <div>
        <!-- <Menu @menuChange="menuChange"></Menu> -->
        <TopBanner :srcTop = "srcTop"  :object="false" :insideBanner="true"></TopBanner>
        <Module ModuleTitle1="入户门系列" ModuleTitle2="" ModuleTitleEng="Product series">
            <template #content>
                <el-tabs v-model="activeName" @tab-click="handleClick" class="tabStyle">
                    <el-tab-pane v-for="item in doorsType" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
                </el-tabs><el-row>
                <el-col>
                    <div class="caseContent">
                        <div v-for="item in doorsData" :key="item.id" class="caseContentHover" ref="text" :style="{'height': fitHeight + 'px',}"  @click="goProductDetails(item)"> 
                            <div class="caseContentImg"><img :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;"></div>
                            <div class="positionAbsolute">
                                <div class="caseContentHoverSee">
                                    <img src="@/static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <div class="CommercialProductPagination">
                        <el-pagination  
                            prev-text="上一页" next-text="下一页"
                            background
                            layout="prev, pager, next"
                            :page-size="currentSize"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :total="total">
                        </el-pagination>
                    </div>
                </el-col>  
        </el-row> 
            </template>
        </Module>
        
        <!-- <el-row>
            <el-col>
                <div class="caseContent">
                    <div v-for="item in doorsData" :key="item.id" class="caseContentHover" ref="text" :style="{'height': fitHeight + 'px',}"  @click="goDetails(item)"> 
                        <div class="caseContentImg"><img :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;"></div>
                        <div class="positionAbsolute">
                            <div class="caseContentHoverSee">
                                <img src="../static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="CommercialProductPagination">
                    <el-pagination  
                        prev-text="上一页" next-text="下一页"
                        background
                        layout="prev, pager, next"
                        :page-size="currentSize"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :total="total">
                    </el-pagination>
                </div>
            </el-col>  
        </el-row>   -->
        <Footer>
            <template #footerBanner>
                <img :src=" $SrcUrl + srcFooter" alt="">
            </template>
        </Footer>
    </div>
</template>
  
<script>
import Menu from "@/components/Menu"
import TopBanner from "@/components/TopBanner"
import Module from "@/components/Module"
import Footer from "@/components/Footer"
import { pictureData } from "@/utils/advertiseImgData"
export default {
    name: "ProductCenter",
    components: {
        Menu,Module,Footer,TopBanner
    },
    data () {
    return {
        productCenterCur:0,
        srcTop:[],
        srcFooter:[require('@/static/fooderImg1.png')],
        activeName: '15',
        currentPage:1,
        currentSize:6,
        total:0,
        doorsType:[],
        doorsData:[],
        productHeight:'',
        fitHeight:'',
    }
    },
    watch:{
        'activeName':{
            handler(newVal) {
                console.log('activeNamenewVal',newVal,typeof newVal);
                this.activeName = newVal
                this.getDoorsData();
            }
        }
    },
    mounted(){
        if(this.$route.query.tabSelect){
            this.getTabParams()
        }
        // this.getParams();
        if(sessionStorage.getItem('TypeId')){
            this.activeName = sessionStorage.getItem('TypeId')
            this.getAdvertise();
        }else{
            this.getAdvertise();
        }
        
        this.getDoorsData();
        this.$http.get('/system/productType/getProTypeR').then(res => {
          this.doorsType = res
        })
        
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val
        this.getDoorsData();
      },
      goProductDetails(item){
        const id = item.id
        this.$router.push({
            name:'ProductDetails',
            query:{
                id
            }
        })
      },
      getDoorsData(){
        this.$http.get('/system/product/getProR',{'pageNum':this.currentPage, 'pageSize': this.currentSize,'productTypeid': this.activeName}).then(res => {
            this.doorsData = res.rows;
            this.total = res.total;
            console.log('this.doorsData',this.doorsData);
            if(this.doorsData.length != 0){
              this.getCaseFitHeight()
            }
        })
      },
      getAdvertise(){
        this.$http.get('/system/advertisement/getAdvR').then(res => {
          let { topImg, footerImg } = pictureData({picExplain:'产品', data:res})
          if(topImg .length > 0){
            this.srcTop = topImg[0].data;
          }
          if(footerImg .length > 0){
            this.srcFooter = footerImg[0].data
          }
        })
      },
      getTabParams(){
        var paramsTabSelect = sessionStorage.getItem("paramsTabSelect");
        if(paramsTabSelect){
            var params = JSON.parse(sessionStorage.getItem("paramsTabSelect"));
        }else{
            var params = this.$route.query.tabSelect;
            sessionStorage.setItem("paramsTabSelect", JSON.stringify(params));
        }
        console.log('paramsTabSelect',paramsTabSelect);
        this.activeName = params; 
      },
      getCaseFitHeight(){ 
        setTimeout(()=>{
            this.fitHeight = (this.$refs.text[0].offsetWidth)*2/3
            console.log('this.fitHeight',this.fitHeight);
        },100)
      },
      
      menuChange(index){
        this.activeName = (index).toString()
        this.getDoorsData();
      }
    },
    // 页面销毁之前
  beforeDestroy() {
    // sessionStorage.removeItem('TypeId')
  },
}
</script>

<style lang="scss" scoped>
.productBanner{
    height: 801px;
}
// 标签页
.tabStyle ::v-deep .el-tabs__nav-wrap::after{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 0px;
    background-color: rgba(0,0,0,0);
    z-index: -1;
}
.tabStyle ::v-deep .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
}
.tabStyle ::v-deep .el-tabs__active-bar{
    height: 0;
}
.tabStyle ::v-deep .el-tabs__item.is-active {
    color: #FFFFFF;
    background: #000000;
    border-radius: 4px;
}
.tabStyle ::v-deep .el-tabs__item:hover{
    color: #000000;
}
.tabStyle ::v-deep .el-tabs__item.is-active:hover{
    color: #FFFFFF;
}
.tabStyle ::v-deep .el-tabs__item{
    padding: 4px 18px !important;
    height: unset;
    line-height: unset;
    margin: 0 50px;
}


.caseContent{
    width: 80%;
    margin: 0 18.5%;
}
  .caseContentHover{
    float: left;
    position: relative;
    width: 25%;
    margin: 10px;
    // border-radius: 12px!important;
    // border-bottom-left-radius: 12px!important;
    // border-bottom-right-radius: 12px!important;
    // overflow: hidden;
  }
  .caseContentImg{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }
  .positionAbsolute{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    background: rgba($color: #000000, $alpha: 0.6);
        .caseContentHoverSee{
          display: none;
        }
        .caseContentText{
          display: none;
        }
    }
    .caseContentHover:hover{
        .positionAbsolute{
            height: 100%;
            .caseContentText{
                display: none;
            }
            .caseContentHoverSee{
                display: block;
            }
        }
    }

.clear{
    clear: both;
}
/* 分页样式 */
.CommercialProductPagination{
    display: flex;
    justify-content: center;
    margin: 30px 0;
}
.CommercialProductPagination ::v-deep .el-pagination.is-background .btn-next,
.CommercialProductPagination ::v-deep  .el-pagination.is-background .btn-prev,
.CommercialProductPagination ::v-deep .el-pagination.is-background .el-pager li {
    background: #FFFFFF;
    border: 1px solid #A7A7A7;
}

.CommercialProductPagination ::v-deep .el-pagination.is-background .el-pager li:hover{
    color: #000000;
}
.CommercialProductPagination ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #000000;
    color: #fff;
  }
.CommercialProductPagination ::v-deep .el-pagination .btn-prev,.CommercialProductPagination ::v-deep .el-pagination .btn-next {
    width: 64px;
}
</style>