export const pictureData = (dataAll) => {
    // console.log('dataAll',dataAll);
    let resultData = {}
    let topData = []
    let footerData = []
    dataAll.data.forEach(element => {
        // console.log('element',element);
        if(element.picExplain == dataAll.picExplain && element.position == '1'){
            if(element.picture){
                topData.push({'data':element.picture,'type':'图片'})
            }
            if(element.vedio){
                topData.push({'data':element.vedio,'type':'视频'})
            }
        }else if(element.picExplain == dataAll.picExplain && element.position == '0'){
            if(element.picture){
                footerData.push({'data':element.picture,'type':'图片'})
            }
            if(element.vedio){
                footerData.push({'data':element.vedio,'type':'视频'})
            }
        }
    });
    resultData['topImg'] = topData
    resultData['footerImg'] = footerData
    // console.log('resultData',resultData);
    return resultData
}

