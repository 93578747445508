<template>
  <div class="Demo">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
  export default {
    name: 'Demo',
    data () {
      return {
        index: 0, // 索引
        delay: 500, // 滚动事件间隔时间
        lock: false // 锁
      }
    },
    mounted () {
      this.$el.addEventListener('mousewheel', this.mousewheelHandler)
    },
    watch: {
      index (newVal, oldVal) {
        if (newVal !== oldVal) { // 防止往相同的位置滚动后就要无辜等待delay秒才能再次滚动
          this.lock = true
        }
      }
    },
    methods: {
      mousewheelHandler (event) {
        let value = event.wheelDelta > 0 ? 'up' : 'down'
        this.scrollHandler(value)
      },
      scrollHandler (value) {
        console.log('------------',value);
        if (this.lock) {
          return
        }
        let mapIndex = {
          up: Math.max(0, this.index - 1),
          down: Math.min(this.$el.children.length - 1, this.index + 1)
        }
        this.index = mapIndex[value]
        this.$el.children[this.index].scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => this.lock = false, this.delay)
      }
    }
  }
</script>
<style scoped>
  .Demo {
    overflow: hidden;
  }
  .Demo,
  .Demo > div {
    height: 100%;
  }
  .Demo > div:nth-child(1) {
    background-color: #f00;
  }
  .Demo > div:nth-child(2) {
    background-color: #0f0;
  }
  .Demo > div:nth-child(3) {
    background-color: #00f;
  }
</style>