<template>
  <div class="content">
      <!-- <Menu></Menu> -->
      <TopBanner :srcTop = "srcTop"  :object="false" :insideBanner="true"></TopBanner>
      <div>
          <div id="item1">
              <Module ModuleTitle1="企业介绍" ModuleTitle2="" ModuleTitleEng="Company introduction">  
                  <template #content>
                  <AboutUs :showOrHide="false"></AboutUs>
                  </template>
              </Module>
          </div>
      </div>
      <Footer>
        <template #footerBanner>
          <img :src=" $SrcUrl  + srcFooter" alt="">
        </template>
      </Footer>
  </div>
</template>

<script>
import Menu from "@/components/Menu"
import TopBanner from "@/components/TopBanner"
import Module from "@/components/Module"
import AboutUs from "@/components/AboutUs"
import Honors from "@/components/Honors"
import CompanyStrength from "@/components/CompanyStrength"
import Footer from "@/components/Footer"
import { pictureData } from "@/utils/advertiseImgData"

export default {
  name: "Introduction",
  components: {
    Menu,Module,AboutUs,CompanyStrength,Footer,Honors,TopBanner
  },
  data () {
    return {
      windowHeight:'',
      srcTop:[],
      srcFooter:[],
    }
  },
  mounted(){
    this.toPoint();
    this.windowHeight = window.innerHeight;
    this.getAdvertise();
  },
  methods: {
    menuChange(data) {
      let toElement = document.getElementById(data.pointId);
      toElement.scrollIntoView(true);
      // this.activeName = data.pointIdCur
    },
    toPoint() {
      console.log("----------------");
    // 存在参数Anchor  则滚动到指定位置，否则不滚动
      let key = this.$route.query.targetId
      if (key) {
        let view = document.getElementById(key);
        console.log('viewviewviewview',view);
        view.scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block: "start"  // 上边框与视窗顶部平齐。默认值
        });
      }
    }, 
    getAdvertise(){
      this.$http.get('/system/advertisement/getAdvR').then(res => {
        let { topImg, footerImg } = pictureData({picExplain:'企业介绍', data:res})
        if(topImg .length > 0){
          this.srcTop = topImg[0].data;
        }
        if(footerImg .length > 0){
          this.srcFooter = footerImg[0].data
        }
      })
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.removeItem("goToId");
  },
  created() {
},
}
</script>

<style scoped>
.content{
  width: 100%;
}
</style>