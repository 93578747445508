import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../views/Main.vue'

import ProductCenter from '@/views/ProductCenter.vue'
import ProductDetails from '@/views/ProductDetails'

import CompanyProfile from '../views/CompanyProfile.vue'
import Introduction from '../views/Introduction.vue'
import Strength from '../views/Strength.vue'
import Hornor from '../views/Hornor.vue'

import EngineeringCase from '../views/EngineeringCase.vue'
import NewsCenter from '../views/NewsCenter.vue'
import InviteCooperation from '../views/InviteCooperation.vue'
import ContactUs from '../views/ContactUs.vue'
import DetailsPage from '../views/DetailsPage.vue'

import SerchPage from '../views/SerchPage.vue'

import test from '../views/test.vue'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Main',
  //   component: Main,
  // },
  {path:'/',name:'home',redirect:'/Main'},
  {path:'/Main',name:'Main',component:Main},
// 产品
  {
    path: '/ProductCenter',
    name: 'ProductCenter',
    component: ProductCenter,
  },
  {
    path: '/ProductDetails',
    name: 'ProductDetails',
    component: ProductDetails,
  },



  {
    path: '/CompanyProfile',
    name: 'CompanyProfile',
    component: CompanyProfile,
  },{
    path: '/Introduction',
    name: 'Introduction',
    component: Introduction,
  },{
    path: '/Strength',
    name: 'Strength',
    component: Strength,
  },{
    path: '/Hornor',
    name: 'Hornor',
    component: Hornor,
  },


  {
    path: '/EngineeringCase',
    name: 'EngineeringCase',
    component: EngineeringCase,
  },
  {
    path: '/NewsCenter',
    name: 'NewsCenter',
    component: NewsCenter,
  },
  {
    path: '/InviteCooperation',
    name: 'InviteCooperation',
    component: InviteCooperation,
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
  },
  {
    path: '/DetailsPage',
    name: 'DetailsPage',
    component: DetailsPage,
  },
  {
    path: '/SerchPage',
    name: 'SerchPage',
    component: SerchPage,
  },
  {
    path: '/test',
    name: 'test',
    component: test,
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
