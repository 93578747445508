<template>
  <div class="content">
      <!-- <Menu></Menu> -->
      <TopBanner :srcTop = "srcTop"  :object="false"></TopBanner>
      <div>
      <div id="item1">
        <Module ModuleTitle1="企业介绍" ModuleTitleEng="Company introduction">  
          <template #content>
            <AboutUs></AboutUs>
          </template>
        </Module>
      </div>
      <div id="item2">
        <Module ModuleTitle1="企业优势" ModuleTitleEng="Company introduction">
          <template #content>
            <CompanyStrength></CompanyStrength>
          </template>
        </Module>
      </div>
      <div id="item3">
        <Module ModuleTitle1="荣誉资质" ModuleTitleEng="Honor and qualification">
          <template #content>
            <Honors></Honors>
          </template>
        </Module>
      </div>
      </div>
      <Footer>
        <template #footerBanner>
          <img v-if="srcFooter"  :src=" $SrcUrl  + srcFooter" alt="">
        </template>
      </Footer>
  </div>
</template>

<script>
import Menu from "@/components/Menu"
import TopBanner from "@/components/TopBanner"
import Module from "@/components/Module"
import AboutUs from "@/components/AboutUs"
import Honors from "@/components/Honors"
import CompanyStrength from "@/components/CompanyStrength"
import Footer from "@/components/Footer"
import { pictureData } from "@/utils/advertiseImgData"

export default {
  name: "CompanyProfile",
  components: {
    Menu,Module,AboutUs,CompanyStrength,Footer,Honors,TopBanner
  },
  data () {
    return {
      windowHeight:'',
      srcTop:[],
      srcFooter:[],
    }
  },
  methods: {
    getAdvertise(){
      this.$http.get('/system/advertisement/getAdvR').then(res => {
        let { topImg, footerImg } = pictureData({picExplain:'公司介绍', data:res})
            if(topImg .length > 0){
              this.srcTop = topImg[0].data;
            }
            if(footerImg .length > 0){
              this.srcFooter = footerImg[0].data
            }
      })
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.removeItem("goToId");
  },
  mounted(){
    this.windowHeight = window.innerHeight;
    this.getAdvertise();
    
  },
  created() {
},
}
</script>

<style scoped>
.content{
  width: 100%;
}
</style>